import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";

// Routers
import explore from "./routes/explore";
import classrooms from "./routes/classrooms";
import personal from "./routes/personal";
import authentication from "./routes/authentication";
import cart from "./routes/cart";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/explore/exploreList/exploreList.vue"),
      meta: {
        pageTitle: "Explore",
        navActiveLink: "Explore",
      },
    },
    ...authentication,
    ...personal,
    ...classrooms,
    ...explore,
    ...cart,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (to.query.ci) {
    window.sessionStorage.setItem("ci", to.query.ci);
  }

  if (!canNavigate(to)) {
    if (!isLoggedIn && to.path !== "/login") return next({ name: "Login" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    if (to.query.guestToken) return next();

    next("/");
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
