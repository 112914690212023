import axiosIns from '@axios'

export default {
  state: {
    userId: null,
    userData: {},
    is_blocked: false,
    role: null,
  },
  getters: {
    getActiveCurrency(state) {
      return state?.userData.active_currency_id
    },
  },
  mutations: {},
  actions: {
    FETCH_DATA_USER({ state }) {
      axiosIns
          .get('auth/student/profile')
          .then(res => {
          if (res.status === 200) {
            const {data} = res.data
            state.userData = data.user
            state.userId = data.user.id
            state.is_blocked = data.user.is_blocked
            state.role = data.user.role
          }
        })
          .catch(err => {
            console.log(err)
          })
    },
  },
}
