export default [
  {
    path: "/explore",
    name: "Explore",
    component: () => import("@/views/explore/exploreList/exploreList.vue"),
  },
  {
    path: "/explore/course/:id",
    name: "ExploreCourses",
    component: () => import("@/views/explore/showExplore/showExplore.vue"),
  },
];
